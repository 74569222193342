"use client";
import { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import TransitionLayout from "@/components/TransitionLayout";
import TransitionInner from "@/components/TransitionInner";
import Link from "next/link";
import { Button, Card, CardBody } from "@nextui-org/react";

export default function Page() {
  const container = useRef(null);
  const stickyMask = useRef(null);

  const initialMaskSize = 0.9;

  const targetMaskSize = 30;
  const easing = 0.15;

  let easedScrollProgress = 0;
  const animate = () => {
    if (stickyMask.current) {
      const maskSizeProgress = targetMaskSize * getScrollProgress();

      stickyMask.current.style.webkitMaskSize =
        (initialMaskSize + maskSizeProgress) * 100 + "%";
    }

    requestAnimationFrame(animate);
  };

  const getScrollProgress = () => {
    const scrollProgress =
      stickyMask.current.offsetTop /
      (container.current.getBoundingClientRect().height - window.innerHeight);
    const delta = scrollProgress - easedScrollProgress;

    easedScrollProgress += delta * easing;

    return easedScrollProgress;
  };

  useEffect(() => {
    requestAnimationFrame(animate);
  }, []);

  return (
    <TransitionInner>
      <main>
        <motion.div
          content="page"
          ref={container}
          animate={{ background: "linear-gradient(#69908e, #b2b2b2)" }}
          className="relative h-[300vh] transition-colors duration-500"
        >
          <div
            ref={stickyMask}
            className="flex overflow-hidden sticky top-0 h-screen items-center justify-center md:[mask-image:url('/huoerhaiya_xs_1.svg')] [mask-image:url('/huoerhaiya.svg')] [mask-position:center_center] [mask-repeat:no-repeat] [mask-size:90%]"
          >
            <video autoPlay muted loop className="h-full w-full object-cover">
              <source src="/huoerhaiyaPV.mp4" type="video/mp4" />
            </video>
          </div>
        </motion.div>
      </main>

      <footer
        className="h-[800px] bg-black relative"
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        }}
      >
        <div className="fixed h-[800px] w-full bottom-0  flex justify-center items-center flex-col">
          <Card className="dark mt-4">
            <CardBody>
              <p className="text-white">
                可以在菜单中进入与霍尔海雅聊天、当前功能仍在开发测试中
              </p>
            </CardBody>
          </Card>
        </div>
      </footer>
    </TransitionInner>
  );
}
