"use client";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
const anim = (variants) => {
  return {
    initial: "initial",
    animate: "enter",
    exit: "exit",
    variants,
  };
};
export default function TransitionLayout({ children }) {
  const [dismension, setDismension] = useState({
    width: 0,
    height: 0,
  });
  const router = useRouter();
  console.log("router", router);

  useEffect(() => {
    const resize = () => {
      setDismension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="min-h-[calc(100vh)]">
      {dismension.width > 0 && <SVG {...dismension} />}
      {dismension.width > 0 && children}
    </div>
  );
}

const SVG = ({ width, height }) => {
  const initialPath = `
        M0 300 
        Q${width / 2} 0 ${width} 300
        L${width} ${height + 300}
        Q${width / 2} ${height + 600} 0 ${height + 300}
        L0 300
    `;

  const targetPath = `
    M0 300 
    Q${width / 2} 0 ${width} 300
    L${width} ${height}
    Q${width / 2} ${height} 0 ${height}
    L0 300
`;

  const curve = {
    initial: {
      d: initialPath,
    },

    enter: {
      d: targetPath,
      transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] },
    },

    exit: {
      d: initialPath,
      transition: { duration: 0.75, ease: [0.76, 0, 0.24, 1] },
    },
  };

  const slide = {
    initial: {
      top: "-300px",
    },
    enter: {
      top: "-100vh",
      transition: { duration: 0.75, delay: 0.35, ease: [0.76, 0, 0.24, 1] },
      transitionEnd: {
        top: "100vh",
      },
    },
    exit: {
      top: "-300px",
      transition: { duration: 0.75, ease: [0.76, 0, 0.24, 1] },
    },
  };

  return (
    <motion.svg
      {...anim(slide)}
      className="w-screen h-[calc(100vh+600px)] top-0 left-0 fixed cursor-none z-50"
    >
      <motion.path {...anim(curve)}></motion.path>
    </motion.svg>
  );
};
