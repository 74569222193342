import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const anim = (variants) => {
  return {
    initial: "initial",
    animate: "enter",
    exit: "exit",
    variants,
  };
};
export default function TransitionInner({ children }) {
  const opacity = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 1,
    },
  };

  const slide = {
    initial: {
      top: "100vh",
    },
    enter: {
      top: "100vh",
    },
    exit: {
      top: 0,
      transition: {
        duration: 1,
        ease: [0.76, 0, 0.24, 1],
      },
    },
  };

  return (
    <div className="bg-black relative">
      <AnimatePresence>
        <motion.div
          key={1}
          {...anim(slide)}
          className="h-screen w-screen fixed top-0 left-0 bg-red-300"
          style={{
            zIndex: 100,
          }}
        />
        <motion.div
          key={2}
          {...anim(opacity)}
          className="TransitionInner h-screen"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
